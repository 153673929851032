<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="indicators">
          <div class="row">
            <div class="item" v-for="i in 23" :key="i"></div>
          </div>
          <div class="row">
            <div class="item" v-for="i in 23" :key="i"></div>
          </div>
          <div class="row">
            <div class="item" v-for="i in 23" :key="i"></div>
          </div>
        </div>
        <img src="./../images/heroLines.svg" class="heroLines"/>
        <div class="wrapper">
          <img src="./../images/arrows.svg" class="arrows"/>
          <div class="title huge">AI</div>
          <div class="desc">IS ABOUT EVERYTHING</div>
          <div class="title">PUSH YOUR LIMITS</div>
        </div>
      </div>
      <div class="section who-we-are-section">
        <div class="wrapper">
          <div class="left">
            <div class="title big">WHO ARE WE ?</div>
          </div>
          <div class="right">
            <div class="desc big">
              Robomuses is your provider to the collaborative future of content creation.
            </div>
          </div>
        </div>
      </div>  
      

      <div class="section waiting-section">
        <div class="wrapper wrapper-first">
          <div class="title">
            <span>
              //: CONTENT YOU'VE BEEN WAITING FOR
            </span>
          </div>
        </div>
        <div class="line"></div>
        <div class="wrapper wrapper-second">
          <img src="./../images/images.png" class="images"/>
          <img src="./../images/videos.png" class="videos"/>
        </div>
      </div>
      <div class="section what-we-offer-section">
        <div class="wrapper">
          <img src="./../images/arrows-right.svg" class="arrows-right1"/>
          <img src="./../images/arrows-right.svg" class="arrows-right2"/>
          <div class="left">
            <img src="./../images/aim.svg" class="aim"/>
            <div class="title big">
              WHAT WE OFFER?
            </div>
          </div>
          <div class="right">
            <div class="desc big">
              We sell and buy AI generated content license and stress free. 
              Are you an AI artist looking to sell your work, or perhaps
              a content creator who needs copyright-free content? 
            </div>
            <div class="desc big">
              We can help you with that! Simply create an account and unleash the opportunities with ____.
            </div>
            <router-link to="/register" class="desc medium">
              <span>
                <div class="bracket">[</div> CLICK HERE TO CREATE ACCOUNT <div class="bracket">]</div>
              </span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="section proud-section">
        <img src="./../images/proud.png" class="proud"/>
        <div class="line1"></div>
        <div class="wrapper wrapper-first">
          <div class="title">
            <span>
              //: Something we are proud of
            </span>
          </div>
        </div>
        <div class="line"></div>
        <div class="wrapper wrapper-second">
          <div class="cards">
            <div class="card">
              <img src="./../images/corners.png" class="corners"/>
              <div class="card-wrapper">
                <div class="card-head">
                  <div class="desc medium">OUR FAVOURITES</div>
                </div>
                <div class="card-body">
                  <img src="./../images/favorites.png" class="img"/>
                  <div class="image-list">
                    <img 
                      v-for="item in favoritesList.slice(0,9)" 
                      :key="item.id" 
                      :src="imgDomain + item.doc_url" 
                      class="image"
                      @click="$emit('goToProduct', item)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <img src="./../images/corners.png" class="corners"/>
              <div class="card-wrapper">
                <div class="card-head">
                  <div class="desc medium">MOST PURCHASED</div>
                </div>
                <div class="card-body">
                  <img src="./../images/most-purchased.png" class="img"/>
                  <div class="image-list">
                    <img 
                      v-for="item in favoritesList.slice(0,9)" 
                      :key="item.id" 
                      :src="imgDomain + item.doc_url" 
                      class="image"
                      @click="$emit('goToProduct', item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section nav-bottom-section">
        <Nav />
      </div>
    </div>
  </main>
</template>
<script>
import Nav from '../components/Nav.vue'

export default {
  name: 'Home',
  props: ['currency', 'categories'],
  components: {
    Nav
  },
  data: function() {
    return {
      imgDomain: '',
      favoritesList: []
    }
  },
  mounted() {
    this.getFavoritesList();
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    
  },
  methods: {
    
    getFavoritesList() {
      this.favoritesList = [];

      this.$http.get(process.env.VUE_APP_API + 'products')
      .then((resp) => {
        this.favoritesList = resp.data.payload.filter(item => (item.product_type === 'image'));
      })
      .catch(() => {
        
      })
      
			
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openReadyModal() {
			this.$parent.openReadyModal();
			this.$parent.setReadyPack();
		},
  
  }
}
</script>
<template>
  <div class="modal ready-modal">
    <div class="overlay"  @click="$parent.closeReadyModal"></div>
    <div class="wrapper">
<!--      <img class="close" @click="$parent.closeReadyModal" src="./../images/close.svg"/>-->
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="modal-head">
                <img class="close" @click="$parent.closeReadyModal" src="./../images/close.svg"/>
                <div class="title">Purchase product?</div>
              </div>
              <div class="title small center">Are you sure you want to buy this product ?</div>
              <div class="buttons">
                <button class="button" @click="$parent.closeReadyModal">
                  <span>Cancel</span>
                </button>
                <button :class="['button blue', {'disabled': $parent.addToCartBtnLoader}]" @click="addToCart">
                  <span>Yes</span>
                </button>
              </div>
              <transition name="fade">
                <div class="desc error-desc red" v-if="$parent.error">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReadyModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  
  methods: {
		addToCart() {
			this.$emit('addToCart')
		}
  }
}
</script>
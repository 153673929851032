<template>
  <main class="main page-inside video-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">
            <span>
              //: New era of AI coming soon ...
            </span>
          </div>
        </div>
      </div>
      <div class="section nav-bottom-section">
        <Nav />
      </div>
    </div>
  </main>
</template>
<script>
import Nav from '../components/Nav.vue'

export default {
  name: 'Video',
  props: ['categories'],
  components: {
    Nav
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
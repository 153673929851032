<template>
   <main class="main login-page">
    <div class="main-wrapper">
        <div class="container">
          <div class="center">
            <div class="left-up">
              <div @click="$parent.closeSignInModal" class="desc back">
                  Back to mainpage
              </div>
              <div class="form">
                <div class="tabs">
                  <div class="title regular active" v-text="'Forgot password ?'" />
                </div>
                <div class="form-wrapper">
                  <div class="form-fields forgot-pass-content">
                    <div class="form-fields-wrapper">
                        <label>
                        <div class="desc">Email</div>
                        <input type="email" placeholder="Email" v-model="email"/>
                        </label>
                        <div class="cta-container">
                          <button class="button blue" @click="submitForgotPass">
                              <span><span>[</span> Recover <span>]</span></span>
                          </button>
                        </div>
                        <transition name="fade">
                        <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
                        </transition>
                        <transition name="slide">
                        <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                        </transition>
                    </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="left-down">
              <div class="modal-bottom">
                <div class="desc" v-text="'Already have an account?'" />
                <router-link to="/login" class="link desc" v-text="'Sign in!'" />
              </div>
            </div>
          </div>
        </div>
        </div>
    </main>
  </template>
  
  <script>
  
  export default {
    name: 'Recover',
    props: [],
    components: {
    },
    data: function() {
      return {
        email: ''
      }
    },
    mounted() {
      
    },
    methods: {
      submitForgotPass() {
        let data = {
          "email": this.email
        }
        this.$emit('forgotPass', data)
      }
    }
  }
  </script>
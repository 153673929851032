<template>
  <main class="main page-inside text-page faq-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title medium upper">
            <b>FAQ</b>
            <br>
            <span>Common Frequently Asked Questions</span>
          </div>
        </div>
      </div>
      <div class="section text-section">
        <div class="wrapper">
          <div class="item" v-for="(item, index) in faq" :key="item.id">
            <div :class="['desc medium', {'active': isActivePanel(index)}]" @click="togglePanel(index)">{{item.question}}</div>
            <div class="desc answer" v-if="isActivePanel(index)" v-html="item.answer"></div>
          </div>
        </div>
      </div>
      <div class="section nav-bottom-section">
        <Nav />
      </div>
    </div>
  </main>
</template>
<script>
import Nav from '../components/Nav.vue'

export default {
  name: 'Faq',
  props: ['categories'],
  components: {
    Nav
  },
  data: function() {
    return {
      faq: [],
      activePanels: [],
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'faq')
      .then((res) => {
          this.faq = res.data.payload;
      })
      .catch(() => {
        
      })
  },
  methods: {
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
  }
}
</script>
<template>
  <div class="modal withdraw-modal success-create-modal">
    <div class="overlay"  @click="$parent.closeDealModal"></div>
    <div class="wrapper">
      <div class="container">
        <div class="modal-head">
          <img class="close" @click="$parent.closeDealModal" src="./../images/close.svg"/>
          <div class="title">Deal is completed</div>
        </div>
        <div class="modal-body">
          <div class="desc">
            You will receive your funds within 5 business days
          </div>
          <button class="button" @click="$parent.closeDealModal">
            <span>[ OK ]</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DealModal',
  props: [],
  components: {
   
  },
  data: function() {
    return {
     
    }
  },
  methods: {

  }
}
</script>
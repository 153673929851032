<template>
  <main class="main product-list-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="breadcrumbs" v-if="activeCategory">
          <div v-if="$parent.categories && activeType == 'doc'" class="desc link" @click="$emit('chooseType', $parent.categories[1])">
            {{ $parent.categories[1].title }}
          </div>
          <div v-if="categories && activeType == 'image'" class="desc link" @click="$emit('chooseType', $parent.categories[0])">
            {{ $parent.categories[0].title }}
          </div>
          <div class="desc" v-if="activeCategory">
            Home
          </div>
          <div class="desc" v-if="activeCategory">/</div>
          <div class="desc" v-if="activeCategory">
            Images
          </div>
          <div class="desc" v-if="activeCategory">/</div>
          <div class="desc link" v-if="activeCategory">{{ activeCategory.replace(/_/g, " ") }}</div>
        </div>
        <div class="wrapper first">
          <div class="left">
            <div class="input-container">
              <input type="text" placeholder="Enter request" @input="filterProducts" v-model="search"/>
              <button class="button blue" @click="filterProducts">
                <img src='./../images/glass.svg' class="img"/>
              </button>
            </div>
            <div class="more-filters" @click="$parent.openFilterModal()">
              <img src='./../images/filters.svg' class="img"/>
            </div>
            <div class="reset-filters desc" @click="resetFilters">Reset all fillters</div>
            <div :class="{'no-tags': !activeTag.length}" class="tags">
              <div v-for="tag in activeTag" class="button tags__item" :key="tag">
                {{ tag }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="select-container">
              <select v-model="sort" @change="filterProducts">
                <option selected hidden disabled value="">Sort by date</option>
                <option value="desc">Newest</option>
                <option value="asc">Oldest</option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <section class="section product-list-section2">
        <div class="wrapper">
          <div class="top" v-if="productList && !productList.length">
            <div class="desc">No products found</div>
          </div>
          <div class="top" v-if="productList && productList.length">
            <div class="desc">Found <b>{{productList.length}}</b> items by your request</div>
          </div>
          <transition name="fade">
            <div class="list products" v-if="productList && productList.length">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <ProductCard
                  :error="$parent.error"
                  :item="item" 
                  :sliderIsClickble="true"
                  :currency="currency" 
                  :categories="categoryOptions"
                  :addToCartChosenItem="addToCartChosenItem"
                  @goToProduct="goToProduct" 
                  @openBuyModal="openBuyModal" 
                  @addToCart="addToCart"
                  @openReadyModal="openReadyModal"
                />
              </div>
              <div class="title no-products" v-if="!productList.length">
                No products!
              </div>
              <div 
                v-if="productList.length != productListTotal"
                class="desc load-more"
              >
                <span @click="loadMore">LOAD MORE ...</span>
              </div>
            </div>
          </transition>
        </div>
      </section>
      <div class="section nav-bottom-section">
        <Nav />
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from '../components/ProductCard.vue';
import Nav from '../components/Nav.vue'

export default {
  name: 'ProductList',
  props: ['currency', 'filter', 'appActiveType','categories', 'addToCartChosenItem'],
  components: {
    ProductCard,
    Nav
  },
  data: function() {
    return {
      imgDomain: '',
      sort: 'desc',
      category: [],
      model: [],
      productList: [],
      activeCategory: '',
      activeTag: '',
      activeType: '',
      categoryOptions: [],
      search: '',
      activeCategoryId: '',
      productsLimit: 4,
      productListTotal: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    let filterIsSet = false;
    if (this.$route.params) {
      this.activeCategory = this.$route.params.type;
      filterIsSet = true;
    } else {
      this.activeCategory = '';
    }

    if (this.$route.query['filter[tag]']) {
      this.activeTag = this.$route.query['filter[tag]'];
      filterIsSet = true;

      let newArray = [];
      let wordsArray = this.activeTag.split(',');
      wordsArray.forEach(word => {
          newArray.push(word.trim());
      });
      this.activeTag = newArray;
      
    } else {
      this.activeTag = '';
    }

    this.checkType();

    if (filterIsSet) {
      this.filterProducts('type');
    }
  },

	watch: {
    categories() {
      if (this.$route.params) {
        this.activeCategory = this.$route.params.type;
      }
      if (this.$route.params.type) {
        this.filterProducts('type');
      }
    },
    filter: function (newArr) {
      this.filterProducts(newArr);
    },
    currency: function() {
      // this.filterProducts();
    },
    $route() {
      this.checkType();

      if (!this.$route.query['filter[category]']) {
        this.activeCategory = ''
      }

      if (!this.$route.query['filter[tag]']) {
        this.activeTag = ''
      }
    },
    appActiveType: function() {
      this.productList = [];
      this.activeCategory = '';
    },
	},
  computed: {
    activeCategories() {
      let categoryIds = this.activeCategory;

      if (categoryIds && this.categoryOptions) {
        if (typeof categoryIds === 'string' ) {
          categoryIds = categoryIds.split(',').map((id) => parseInt(id))
        } else if (Number.isInteger(categoryIds)) {
          categoryIds = [categoryIds];
        }

        return this.categoryOptions.filter(({id}) => categoryIds.includes(id));
      }

      return [];
    }
  },
  methods: {
    openReadyModal(item) {
      this.$emit('openReadyModal', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    loadMore() {
      this.productsLimit = this.productsLimit + 10;
      this.filterProducts('type');
    },
    resetFilters() {
      const currentPath = this.$route.path;
      this.activeTag = '';
      
      this.$router.replace({ path: currentPath, query: '' });
      this.filterProducts('type');
    },
    checkType() {
      if (this.$route.params.type === 'images') {
        this.activeType = 'image';
      } else if (this.$route.params.type === 'texts') {
        this.activeType = 'doc';
      }
    },
    setCategory(id) {
      this.activeCategory = id;
      const currentPath = this.$route.path;
      const currentQuery = this.$route.query;
      const newQuery = { ...currentQuery, 'filter[category]': id };
      this.$router.replace({ path: currentPath, query: newQuery });
      this.filterProducts();
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    openBuyModal(item, type) {
      this.$emit('openBuyModal', item, type)
    },
    filterProducts(params) {
      if (params != 'type') {
        if (params && params.activeCategories && Array.isArray(params.activeCategories)) {
          const activeCategories = params.activeCategories;
          this.activeCategory = activeCategories.length ? activeCategories.map(obj => obj.id) : '';
        }

        if (params && params.activeTags && Array.isArray(params.activeTags)) {
          const activeTags = params.activeTags;
          this.activeTag = activeTags.length ? activeTags.map(obj => obj.name) : '';
        }
      }

			let activeCategory;
			let activeTag;
      // if (paramCategories && paramCategories.length) {
      //   const paramIdies = paramCategories.map(obj => obj.id);
      //   this.activeCategory = paramIdies;
      // }

      if (params == 'type') {
        if (this.categories && this.categories.length) {
          let activeCatId = this.categories.find(obj => obj.title.toLowerCase().replace(/\s+/g, '_') === this.activeCategory).id;
          this.activeCategoryId = activeCatId;
        } else {
          return;
        }
      }
			if (this.activeCategory) {
        activeCategory = '&categories_id=' + this.activeCategoryId;
      } else {
        activeCategory = ''
      }

      if (this.activeTag) {
        activeTag = '&tags=' + this.activeTag;
      } else {
        activeTag = ''
      }

      let activeSort;
      if (this.sort) {
        activeSort = '&sort=id~' + this.sort;
      } else {
        activeSort = ''
      }
      let searchText;
      if (this.search) {
        searchText = '&search=' + this.search;
      } else {
        searchText = ''
      }
			this.$http.get(process.env.VUE_APP_API + 'products/catalog?product_type=image&page=1&limit=' + this.productsLimit + activeCategory + activeTag + activeSort + searchText )
			.then((res) => {
        this.productListTotal = res.data.meta.total;
				this.productList = res.data.payload;
			})
			.catch(() => {
				
			})
    },
  }
}
</script>
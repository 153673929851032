<template>
   <main class="main login-page">
      <div class="main-wrapper">
        <div class="container">
          <div class="center">
            <div class="left-up">
              <div @click="$parent.closeSignInModal" class="desc back">
                  Back to mainpage
              </div>
              <div class="form">
                <div class="tabs">
                  <router-link to="/login" class="title regular" v-text="'LOGIN'" />
                  <router-link to="/register" class="title regular" v-text="'SIGN UP'" />
                </div>
                <div class="form-wrapper">
                  <div class="form-fields login-content">
                    <div class="form-fields-wrapper">
                      <label>
                      <div class="desc">Email:</div>
                      <input v-on:keyup.enter="submitLogin" type="email" placeholder="Email" v-model="email"/>
                      </label>
                      <label>
                      <div class="desc">Password:</div>
                      <input v-on:keyup.enter="submitLogin" type="password" placeholder="Password" v-model="pass"/>
                      </label>            
                      <router-link to="/recover" class="link" v-text="'Forgot password?'" />
                      <div class="cta-container">
                      <button class="button" @click="submitLogin">
                        <span><span>[</span> LOGIN <span>]</span></span>
                      </button>
                      </div>
                      <transition name="slide">
                      <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  
  export default {
    name: 'Login',
    props: [],
    components: {
    },
    data: function() {
      return {
        email: '',
        pass: ''
      }
    },
    mounted() {
      
    },
    methods: {
      submitLogin() {
        let loginData = {
          "username": this.email,
          "password": this.pass
        }
        this.$emit('login', loginData)
      },
    }
  }
  </script>
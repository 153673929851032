<template>
  <div class="withdraw-modal withdraw-confirm-modal" v-if="$parent.withdrawConfirmModalIsVisible">
    <ValidationObserver v-slot="{ invalid, pristine, submitting, handleSubmit }" slim>
      <form @submit.prevent="handleSubmit(submit)" class="wrapper">
        <div class="container">
          <div class="form-wrapper">
            <div class="title">Withdraw confirmation</div>
            <div class="desc">
              In order to withdraw money to your account, please provide your bank details
            </div>
            <ValidationProvider name="name" v-slot="{ invalid, dirty }" rules="required" slim>
              <input type="text" placeholder="Name" v-model="name"/>
            </ValidationProvider>
            <ValidationProvider name="surname" v-slot="{ invalid, dirty }" rules="required" slim>
              <input type="text" placeholder="Surname" v-model="surname"/>
            </ValidationProvider>
            <ValidationProvider name="accNum" v-slot="{ invalid, dirty }" rules="required" slim>
              <input type="text" placeholder="Account number" v-model="accNum"/>
            </ValidationProvider>
            <div class="buttons">
              <button class="button" @click="$parent.failWithdrawConfirmModal">
                <span>Cancel</span>
              </button>
              <button type="submit" :disabled="pristine || invalid" class="button blue">
                <span>Proceed with withdrawal</span>
              </button>
            </div>
            <transition name="slide">
              <div v-if="error" class="error-desc desc red">{{error}}</div>
            </transition>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: 'VerifyModal',
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: function() {
    return {
      amount: '',
      error: '',
      name: '',
      surname: '',
      accNum: ''
    }
  },
  methods: {
    submit() {
      this.$http.post(process.env.VUE_APP_API + 'withdraw/send', {
        amount: this.$parent.withdrawAmount,
        account_number: this.accNum,
        first_name: this.name,
        last_name: this.surname
      }).then((res) => {
        const data = res.data;

       if (data.status === 'OK') {
          this.$parent.sucessWithdrawConfirmModal()
        }
      }).catch((res) => {
        this.error = res.response.data.message
        if (res.response.status === 401) {
          this.$parent.failWithdrawConfirmModal();
          this.openSignInModal();
        }
      })
    },
  }
}
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-nav"},_vm._l((_vm.nav),function(item,i){return _c('div',{key:i,staticClass:"nav__item"},[_c('div',{class:['desc', 
        {'active': item == 'OUR SERVICES' && _vm.servicesDropdownIsVisible},
        {'active': item == 'IMAGES' && _vm.imagesDropdownIsVisible},
      ],on:{"click":function($event){return _vm.useLink(item)}}},[_c('span',[_vm._v("[")]),_vm._v(" "+_vm._s(item)+" "),_c('span',[_vm._v("]")])]),_c('transition',{attrs:{"name":"fade"}},[(item == 'OUR SERVICES' && _vm.servicesDropdownIsVisible)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideServicesDropdown),expression:"hideServicesDropdown"}],staticClass:"dropdown"},[_c('router-link',{staticClass:"item desc",attrs:{"to":"/faq"},domProps:{"textContent":_vm._s('FAQ')},nativeOn:{"click":function($event){return _vm.hideServicesDropdown.apply(null, arguments)}}}),_c('router-link',{staticClass:"item desc",attrs:{"to":"/contacts"},domProps:{"textContent":_vm._s('CONTACT US')},nativeOn:{"click":function($event){return _vm.hideServicesDropdown.apply(null, arguments)}}})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(item == 'IMAGES' 
          && _vm.imagesDropdownIsVisible 
          && _vm.$parent.categories 
          && _vm.$parent.categories.length)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideImagesDropdown),expression:"hideImagesDropdown"}],staticClass:"dropdown categories-dropdown"},_vm._l((_vm.$parent.categories),function(item,i){return _c('div',{key:i,staticClass:"link"},[_c('router-link',{staticClass:"item desc",attrs:{"to":'/product-list/' + item.title.toLowerCase().replace(/\s+/g, '_')},domProps:{"textContent":_vm._s(item.title)},nativeOn:{"click":function($event){return _vm.hideImagesDropdown.apply(null, arguments)}}})],1)}),0):_vm._e()])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <header class="header">
    <div class="wrapper">
      <div class="flex">
        <div class="flex-left">
          <router-link to="/" class="logo">
            <img src="./../images/logo.svg" class="img"/>
          </router-link>
        </div>
        <div class="flex-right">
          <Nav/>
          <div class="header-profile" v-if="$parent.isAuth">
            <div class="select-container cart" v-if="curr && ($parent.currencyOptions.length > 1)">
              <select class="select currency-select" v-model="curr"
                      @change="selectCurr()"
              >
                <option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
              </select>
            </div>
            <!-- <div class="balance-block">
              <div class="title small">{{ $parent.userBalance }} {{ $parent.currency }}</div>
            </div> -->
<!--            <router-link to="/cart" class="button cart">-->
<!--              <img class="img" src="./../images/cart.svg"/>-->
<!--              <div v-if="$parent.cartContents && $parent.cartContents.length" class="indicator">-->
<!--                {{ $parent.cartContents.length }}-->
<!--              </div>-->
<!--            </router-link>-->
            <a @click.prevent="$parent.openTopUpModal()" class="button cart topup-balance">
              <img class="img" src="./../images/top_up.svg"/>
              <div v-if="$parent.userBalance">
                €  {{ $parent.userBalance }}
              </div>
            </a>
            <router-link class="profile-desc" to="/profile/my-orders">
              <div class="icon">
                <img class="img" src="./../images/user.svg"/>
              </div>
            </router-link>
          </div>
          <div v-else class="buttons">
            <div class="select-container" v-if="curr && ($parent.currencyOptions.length > 1)">
              <select class="select currency-select" v-model="curr"
                      @change="selectCurr()"
              >
                <option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
              </select>
            </div>
            <a class="desc link" @click="$emit('openSignInModal')">
              <b>SIGN IN</b>
              <img src="./../images/link.svg" class="img"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Nav from '../components/Nav.vue'

export default {
  name: 'Header',
  props: ['currencyCode', 'currencyValue', 'categories'],
  components: {
    Nav
  },
  data: function () {
    return {
      curr: ''
    }
  },
  computed: {
    currOptions() {
      return this.$parent.currencyOptions.map(({code}) => code);
    }
  },
  watch: {
    currencyCode: function (newValue) {
      this.curr = newValue
    }
  },
  mounted() {
    this.curr = this.currencyCode
  },
  methods: {
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    },
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    selectCurr() {
      this.changeCurrency(this.curr)
      localStorage.setItem("currency", this.curr);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
  }
}
</script>

<template>
  <div class="new-nav">
    <div class="nav__item" v-for="(item, i) in nav" :key="i">
      <div 
        :class="['desc', 
          {'active': item == 'OUR SERVICES' && servicesDropdownIsVisible},
          {'active': item == 'IMAGES' && imagesDropdownIsVisible},
        ]" 
        @click="useLink(item)"
      >
      <span>[</span>
        {{ item }}
      <span>]</span>
      </div>
      <transition name="fade">
        <div 
          class="dropdown" 
          v-if="item == 'OUR SERVICES' && servicesDropdownIsVisible"
          v-click-outside="hideServicesDropdown"
        >
          <router-link 
            to="/faq" 
            class="item desc" 
            v-text="'FAQ'"
            @click.native="hideServicesDropdown" 
          />
          <router-link 
            to="/contacts" 
            class="item desc" 
            v-text="'CONTACT US'"   
            @click.native="hideServicesDropdown" 
          />
        </div>
      </transition>
      <transition name="fade">
        <div 
          class="dropdown categories-dropdown" 
          v-if="item == 'IMAGES' 
            && imagesDropdownIsVisible 
            && $parent.categories 
            && $parent.categories.length"
          v-click-outside="hideImagesDropdown"
        >
          <div 
            class="link"
            v-for="(item, i) in $parent.categories" 
            :key="i" 
          >
            <router-link 
              :to="'/product-list/' + item.title.toLowerCase().replace(/\s+/g, '_')" 
              @click.native="hideImagesDropdown"
              class="item desc" 
              v-text="item.title" 
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)
export default {
  name: 'Nav',
  props: [],
  components: {
  },
  data: function() {
    return {
      nav: ['OUR SERVICES', 'IMAGES', 'VIDEO'],
      servicesDropdownIsVisible: false,
      imagesDropdownIsVisible: false
    }
  },
  mounted() {

  },
  methods: {
    hideServicesDropdown() {
      this.servicesDropdownIsVisible = false;
    },
    hideImagesDropdown() {
      this.imagesDropdownIsVisible = false;
    },
    useLink(item) {
      if (item == 'OUR SERVICES') {
        this.servicesDropdownIsVisible = true
      }
      if (item == 'IMAGES') {
        this.imagesDropdownIsVisible = true
      }
      if (item == 'VIDEO') {
        this.$router.push('/video')
      }
    }
  }
}
</script>
<template>
   <main class="main reg-page login-page">
    <div class="main-wrapper">
        <div class="container">
          <div class="center">
            <div class="left-up">
              <div @click="$parent.closeSignInModal" class="desc back">
                  Back to mainpage
              </div>
              <div class="form">
                <div class="tabs">
                  <router-link to="/login" class="title regular" v-text="'LOGIN'" />
                  <router-link to="/register" class="title regular" v-text="'SIGN UP'" />
                </div>
                <div class="form-wrapper">
                  <div class="form-fields login-content">
                    <div class="form-fields-wrapper">
                      <label>
                        <div class="desc">*Name</div>
                        <input type="text" placeholder="Name" v-model="name"/>
                      </label>
                      <label>
                        <div class="desc">*Surname</div>
                        <input type="text" placeholder="Surname" v-model="surname"/>
                      </label>
                      <label>
                        <div class="desc">*Phone</div>
                        <input type="number" placeholder="Phone" v-model="phone"/>
                      </label>
                      <label>
                        <div class="desc">*Email</div>
                        <input type="email" placeholder="Email" v-model="email"/>
                      </label>
                      <label>
                        <div class="desc">*Password</div>
                        <input type="password" placeholder="Password" v-model="pass"/>
                      </label>
                      <div class="chekbox-container">
                        <label class="chekbox-label">
                          <div class="chekbox-label-wrapper">
                            <input type="checkbox" name="terms" v-model="terms"/>
                            <div class="checkbox"></div>
                            <span class="title">I agree with </span>
                            <a @click="$parent.goToPage('privacy')" class="title"> privacy policy</a>
                            <span class="title"> and </span>
                            <a @click="$parent.goToPage('terms')" class="title"> terms and conditions</a>
                          </div>
                        </label>
                      </div>
                      <div class="cta-container">
                        <button :class="['button', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submitRegister">
                          <span><span>[</span> SIGN UP <span>]</span></span>
                        </button>
                      </div>
                      <transition name="slide">
                        <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </main>
  </template>
  
  <script>
  
  export default {
    name: 'Register',
    props: [],
    components: {
    },
    data: function() {
      return {
        email: '',
        pass: '',
        name: '',
        surname: '',
        phone: '',
        terms: false
      }
    },
    computed: {
      requiredRegisterFieldsAreFilled() {
        let fieldsAreFilled;
        if(
          this.name && this.surname  && this.phone && 
          this.email && this.pass 
          && this.terms 
        ) {
          fieldsAreFilled = true;
        } else {
          fieldsAreFilled = false;
        }
        return fieldsAreFilled;
      }
    },
    mounted() {
      
    },
    methods: {
      submitRegister() {
        let regData = {
          "name": this.name,
          "surname": this.surname,
          "email": this.email,
          "phone": this.phone,
          "password": this.pass
        }
          this.$emit('registration', regData)
      }
    }
  }
  </script>
<template>
  <div class="modal sign-modal">
    <div class="overlay"  @click="$emit('closeSignInModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeSignInModal')" src="./../images/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div :class="['form-fields login-content', {'hidden': !loginContentVisible}]">
              <div class="form-fields-wrapper">
                <div class="title">Login</div>
                <label>
                  <div class="desc">Email:</div>
                  <input v-on:keyup.enter="submitLogin" type="email" placeholder="Email" v-model="email"/>
                </label>
                <label>
                  <div class="desc">Password:</div>
                  <input v-on:keyup.enter="submitLogin" type="password" placeholder="Password" v-model="pass"/>
                </label>            
                <div class="link" @click="switchToforgotPass">Forgot password?</div>
                <div class="cta-container">
                  <button class="button blue" @click="submitLogin">
                    <span>Login</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                </transition>
                <div class="modal-bottom">
                  <div class="desc">Don’t have an account?</div>
                  <div class="link switch-register" @click="$emit('openSignUpModal')">Sign up for FREE</div>
                </div>
              </div>
            </div>
            <div :class="['form-fields forgot-pass-content', {'hidden': !forgotPassContentVisible}]">
              <div class="form-fields-wrapper">
                <div class="title">Forgot password?</div>
                <label>
                  <div class="desc">Email</div>
                  <input type="email" placeholder="Email" v-model="email"/>
                </label>
                <div class="cta-container">
                  <button class="button blue" @click="submitForgotPass">
                    <span>Recover</span>
                  </button>
                </div>
                <div class="modal-bottom">
                  <div class="desc">Already have an account?</div>
                  <div class="link switch-login" @click="switchToLogin()">Log in with existing account</div>
                </div>
                <transition name="fade">
                  <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
                </transition>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignInModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: ''
    }
  },
  mounted() {
    
  },
  methods: {
    closeSignInModal() {
      this.$emit('closeSignInModal')
    },
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100)
    },
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    }
  }
}
</script>
<template>
  <div class="modal product-modal">
    <div class="overlay"  @click="$parent.closeProductModal"></div>
    <div class="wrapper">
      <div class="container">
        <transition name="fade">
          <div class="product-preview" v-if="productPageItem">
            <div class="modal-head">
              <img class="close" @click="$parent.closeProductModal" src="./../images/close.svg"/>
              <div class="title">{{productPageItem.title}}</div>
            </div>
            <div class="preview-container">
              <div class="preview-wrapper">
                <div class="preview">
                  <img v-if="productPageItem.product_type == 'image'" :src="$parent.imgDomain + productPageItem.doc_url" class="img"/>
                  <div v-else class="doc-img">
                    <img src='./../images/doc.svg' class="img"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="text">
              <div class="text-wrapper">
                <div class="top">
                  <div class="row">
                    <div class="desc">Description</div>
                    <div class="desc description" v-html="productPageItem.description"></div>
                  </div>
                  <div class="row tags-row">
                    <div class="tags">
                      <!-- <router-link v-for="tag in productPageItem.tags" :key="tag.id" :to="productPageItem.product_type === 'doc' ? `/product-list/texts?filter[tag]=${tag.name}` : `/product-list/images?filter[tag]=${tag.name}` " class="button tags__item"> -->
                       <div v-for="tag in productPageItem.tags" class="button tags__item" :key="tag.id">
                        {{ tag.name }}
                       </div>
                      <!-- </router-link> -->
                    </div>
                  </div>
                </div>
                <div class="price-container">
                  <transition name="fade">
                    <span class="desc green" v-if="addToCartChosenItem == productPageItem.id && !error">Item successfully added to your cart</span>
                  </transition>
                  <transition name="fade">
                    <span class="desc red" v-if="addToCartChosenItem == productPageItem.id && error">{{ error }}</span>
                  </transition>
                  <div class="price title" @click="$parent.closeProductModal(); openReadyModal(productPageItem)">
                    <span class="add-desc">ADD TO CART</span>
                    <span class="price-desc">[ {{productPageItem.price}} <span class="currency">{{currency}}</span> ]</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductModal',
  props: ['productPageItem', 'currency', 'addToCartChosenItem', 'error'],
  components: {
   
  },
  data: function() {
    return {
     
    }
  },
  methods: {
    openReadyModal(item) {
      this.$emit('openReadyModal', item)
    },
  }
}
</script>
<template>
  <div class="modal success-create-modal success-buy-modal">
    <div class="overlay" @click="$parent.closeBuySuccessModal"></div>
    <div class="wrapper">
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="modal-head">
                <img class="close" @click="$parent.closeBuySuccessModal" src="./../images/close.svg"/>
                <div class="title small">You've successfully purchased product.</div>
              </div>
              <div class="desc">
                <div class="">
                Please check Purchase History to view and download product
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SuccessBuyModal',
  props: [],
  components: {},
  data: function () {
    return {}
  },
  methods: {}
}
</script>
<template>
  <div class="modal filter-modal promt-create-modal offer-modal accept-offer">
    <div class="overlay" @click="$parent.closeAcceptOfferModal"></div>
    <div class="wrapper">
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="modal-head">
                <img class="close" @click="$parent.closeAcceptOfferModal" src="./../images/close.svg"/>
                <div class="title">Payment detail</div>
              </div>
              <div class="bottom">
                <div class="desc">
                  Please enter your bank details to accept payment
                </div>
                <div class="input-container">
                  <div class="desc">Bank account number:</div>
                  <input type="text" v-model="iban" />
                </div>
                <div class="input-container">
                  <div class="desc">Full name:</div>
                  <input type="text" v-model="name" />
                </div>
              </div>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
              <div class="buttons">
                <button class="button blue" @click="confirm">
                  <span>[ CONFIRM ]</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AcceptOfferModal',
  props: [],
  components: {
  },
  data: function() {
    return {
     name: '',
     iban: ''
    }
  },
  mounted() {

  },
  methods: {
    confirm() {
      let data = {
        'id': this.$parent.activeOffer,
        'iban': this.iban,
        'full_name': this.name
      }
      this.$http.post(process.env.VUE_APP_API + 'offer/approve', data)
      .then(() => {
        this.$parent.closeAcceptOfferModal();
        this.$parent.closeOfferModal();
        this.$parent.openDealModal();
        this.$parent.getCreatedHistory();
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.$emit('setError', firstErrors[key]);
            }
          }
        } else {
          this.$emit('setError', res.response.data.message);
        }
      })
    }
  }
}
</script>
<template>
  <div class="modal buy-modal">
    <div class="overlay"  @click="$parent.closeBuyModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeBuyModal" src="./../images/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title">Корзина</div>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div>Наименование</div>
                      </th>
                      <th>
                        <div>Цена</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in cartContents" :key="item.id">
                      <td>
                        <div class="flex">
                          <div @click="removeFromCart(item)" class="bin">
                            <img src="./../images/bin.svg" class="img"/>
                          </div>
                          <div class="preview">
                            <img v-if="item.item.product_type == 'image'" :src="$parent.imgDomain + item.item.doc_url" class="img"/>
                            <div class="doc-img" v-else>
                              <img src='./../images/doc.svg' class="img"/>
                            </div>
                            
                          </div>
                          <div class="desc">{{item.item.title}}</div>
                        </div>
                      </td>
                      <td>
                        {{item.item.price}} {{$parent.currency}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="desc clear-cart" @click="$parent.crealCart">Очистить корзину</div>
                      </td>
                      <td><div class="total-desc">Всего:</div> {{totalPrice}} {{$parent.currency}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="bottom">
                <div class="button" @click="$parent.closeBuyModal">Продолжить покупки</div>
                <button class="button blue" @click="buyProduct">Купить</button>
              </div>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyModal',
  props: ['cartContents', 'totalPrice'],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    
  },
  computed: {
    
  },
  methods: {
    buyProduct() {
      this.$parent.buyProduct();
    },
    removeFromCart(item) {
      this.$emit('removeFromCart', item)
    }
  }
}
</script>